import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const NavStyles = styled.nav`
  position: relative;
  z-index: 999;

  a {
    text-decoration: none;
    color: #14284e;
  }

  a:hover {
    color: #b9d3ff !important;
    transition: 300ms ease !important;
  }

  .wrapper {
    top: 0;
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    z-index: 990;
    position: fixed;
    .has-open-nav & {
      border-bottom: 3px solid rgba(0, 0, 0, 0.2);
    }
  }

  .logo {
    margin-left: 100px;
    color: #14284e;
    a {
      text-decoration: none;
      .has-open-nav & {
        color: #14284e;
      }
    }
  }

  .mainMenu {
    z-index: 200;
    display: flex;
    flex-direction: row;
    margin: 0;
    margin-right: 100px;
    top: 100%;
    li {
      list-style: none;
      margin: 20px 0px !important;
    }
  }

  .overflow {
    margin: 0px 20px;
  }

  .hamburger {
    height: 60px;
    width: 35px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    display: none;

    div {
      border-radius: 14px;
      height: 4px;
      width: 100%;
      background-color: #14284e;
      margin-top: 5px;
    }
  }

  #hidden {
    display: none;
    z-index: 10;
  }

  @media (max-width: 1012px) {
    ul {
      display: none;
    }

    .wrapper {
      height: 75px;
      align-items: center;
      display: flex;
    }

    .logo {
      margin-left: 0px;
      font-size: 24px;
      position: relative;
      z-index: 200;
    }

    .hamburger {
      display: flex;
      position: relative;
    }

    .mainMenu {
      left: 100%;
      top: 101%;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      position: absolute;
      justify-content: center;
      width: 100%;
      height: 92vh;
      align-items: center;
      background-color: #ffffff;
      transition: left 300ms ease;

      .has-open-nav & {
        left: 0%;
        top: 101%;
      }
    }

    .mainMenu li {
      font-size: 24px;
    }

    .mainMenu li:last-child {
      padding-bottom: 60px;
    }
  }

  @media (max-height: 600px) {
    .overflow {
      margin: 10px 0px;
    }
    a {
      font-size: 18px;
    }
    li:first-child {
      margin-top: 0 !important;
    }
  }

  .has-white {
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    transition: 300ms ease;
  }

  .has-transparent {
    background: transparent;
    transition: 300ms ease;
  }

  .has-purple-text {
    color: #14284e !important;
    transition: 300ms ease;
  }

  .has-white-text {
    color: #ffffff;
    transition: 300ms ease;
  }

  .hamburger div {
    transition: 500ms ease;
    .has-open-nav & {
      margin-top: -1px;
    }
  }

  .hamburger {
    transition: 500ms ease;
    .has-open-nav & {
      margin-bottom: 0;
    }
    div:nth-of-type(1) {
      .has-open-nav & {
        transform: rotate(45deg);
        position: relative;
        top: -1px;
        transition: 500ms ease;
      }
    }
    div:nth-of-type(2) {
      .has-open-nav & {
        display: none;
        transition: 500ms ease;
      }
    }
    div:nth-of-type(3) {
      .has-open-nav & {
        transform: rotate(-45deg);
        position: relative;
        top: -4px;
        transition: 500ms ease;
      }
    }
  }
  .has-purple {
    background: #14284e !important;
  }
`;

const Nav = () => {
  const resetOverflow = () => {
    const $body = document.querySelector('body');
    $body.classList.remove('toggleoverflow');
  };

  const toggleOverflow = () => {
    const $body = document.querySelector('body');
    $body.classList.toggle('toggleoverflow');
  };

  const closeNav = () => {
    const $body = document.querySelector('body');
    $body.classList.remove('has-open-nav');
  };

  const toggleNav = () => {
    const $body = document.querySelector('body');
    $body.classList.toggle('has-open-nav');
  };

  if (typeof window !== 'undefined') {
    const Navbarscroll = () => {
      const $element = document.querySelector('.frontpage');
      const navElements = document.querySelector('.wrapper');
      const logoElements = document.querySelector('.Logocolor');
      const hamburgers = document.querySelectorAll('.hamburger div');
      if ($element) {
        const linkElements = document.querySelectorAll('.overflow');
        if (window.scrollY > 50) {
          linkElements.forEach((linkElement) => {
            linkElement.classList.add('has-purple-text');
            linkElement.classList.remove('has-white-text');
          });
          navElements.classList.add('has-white');
          logoElements.classList.add('has-purple-text');
          navElements.classList.remove('has-transparent');
          logoElements.classList.remove('has-white-text');
          hamburgers.forEach((hamburger) => {
            hamburger.classList.remove('has-white');
            hamburger.classList.add('has-purple');
          });
        } else {
          linkElements.forEach((linkElement) => {
            linkElement.classList.add('has-white-text');
            linkElement.classList.remove('has-purple-text');
          });
          navElements.classList.add('has-transparent');
          logoElements.classList.add('has-white-text');
          navElements.classList.remove('has-white');
          logoElements.classList.remove('has-purple-text');
          hamburgers.forEach((hamburger) => {
            hamburger.classList.remove('has-purple');
            hamburger.classList.add('has-white');
          });
        }
      } else {
        navElements.classList.add('has-white');
        logoElements.classList.add('has-purple-text');
      }
    };
    window.addEventListener('scroll', Navbarscroll);
  }
  return (
    <NavStyles>
      <div className="wrapper">
        <div className="logo">
          <Link className="Logocolor" onClick={closeNav} to="/">
            Appartement Brixen
          </Link>
        </div>
        <ul className="mainMenu">
          <li>
            <Link
              onClick={() => {
                closeNav();
                resetOverflow();
              }}
              onKeyDown={() => {
                closeNav();
                resetOverflow();
              }}
              className="overflow link"
              to="/"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                closeNav();
                resetOverflow();
              }}
              onKeyDown={() => {
                closeNav();
                resetOverflow();
              }}
              className="overflow link"
              to="/hethuis"
            >
              Het Huis
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                closeNav();
                resetOverflow();
              }}
              onKeyDown={() => {
                closeNav();
                resetOverflow();
              }}
              className="overflow link"
              to="/fotos"
            >
              Foto&apos;s
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                closeNav();
                resetOverflow();
              }}
              onKeyDown={() => {
                closeNav();
                resetOverflow();
              }}
              className="overflow link"
              to="/seizoenen"
            >
              Seizoenen
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                closeNav();
                resetOverflow();
              }}
              onKeyDown={() => {
                closeNav();
                resetOverflow();
              }}
              className="overflow link"
              to="/tarieven"
            >
              Tarieven
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                closeNav();
                resetOverflow();
              }}
              onKeyDown={() => {
                closeNav();
                resetOverflow();
              }}
              className="overflow link"
              to="/contact"
            >
              Contact
            </Link>
          </li>
        </ul>
        <div
          className="hamburger"
          role="button"
          tabIndex="0"
          onClick={() => {
            toggleNav();
            toggleOverflow();
          }}
          onKeyDown={() => {
            toggleNav();
            toggleOverflow();
          }}
        >
          <div />
          <div />
          <div />
        </div>
      </div>
    </NavStyles>
  );
};

export default Nav;
