import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

#gatsby-focus-wrapper
{
    overflow-x: hidden;
}

*:focus {
    outline: 0;
    outline: none;
}

.Navbar-scrolldown
{
    background: #ffffff !important;
}

.Link-Scrolldown
{
    color: #14284e !important; 
}

.Logo-Scrolldown
{
    color: #14284e !important;
}

.Navbar-scrollup
{
    background: transparent !important; 
}

.Link-Scrollup
{
    color: #ffffff !important;
}

.Logo-Scrollup
{
    color: #ffffff !important;
}

body
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.orangebutton
{
    width: 160px;
    height: 50px;
    border-radius: 25px;
    background: #E94822;
    color: #ffffff;
    font-weight: 700;
    font-size: 18px;
    border: none;
    cursor: pointer;
    transition: 150ms ease;
}

.orangebutton:hover
{
    background: #e97822;
    transition: 150ms ease;
}

.purplebutton
{
    width: 160px;
    height: 50px;
    border-radius: 25px;
    background: #14284E;
    color: #ffffff;
    font-weight: 700;
    font-size: 18px;
    border: none;
    cursor: pointer;
    transition: 150ms ease;
}

.purplebutton:hover
{
    background: #1d3974;
    transition: 150ms ease;
}

img
{
    border-radius: 14px;
    box-shadow: 0px 2px 4px rgba(0 ,0 ,0 ,0.5);
    user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.toggleoverflow
{
  overflow: hidden !important;
}

`;

export default GlobalStyles;
