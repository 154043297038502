import { createGlobalStyle } from 'styled-components';

import Bold from '../assets/fonts/Sailec_Bold.woff';
import Regular from '../assets/fonts/Sailec_Regular.woff';

const Typography = createGlobalStyle`

  ${''}
  @font-face {
    font-family: 'Sailec';
    font-weight: bold;
    font-style: normal;
    font-display: block;
    src: url(${Bold});
  }

  ${''}
  @font-face {
    font-family: Sailec;
    font-weight: 900;
    font-style: normal;
    font-display: block;
    src: url(${Regular});
  }

  ${''}
  @font-face {
    font-family: Sailec;
    font-weight: normal;
    font-style: normal;
    font-display: block;
    src: url(${Regular});
  }

  html
  {
    font-family: sans-serif;
  }

  body
  {
    font-family: Sailec, sans-serif;
  }
`;

export default Typography;
